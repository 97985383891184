<template>
  <div class="template-quiz">
    <!-- Search engine active -->
    <div v-show="$store.state.searchStat != false && $store.state.filterSearchEngine != null && $store.state.filterSearchEngine != ''">
      <div class="container-fluid mt-5 search-engine text-white">
        <div class="card" style="background-color:transparent !important;">
          <div class="card-body mx-2">
            <vs-tabs>
              <vs-tab label="Top">
                <!-- <b-overlay
                  :show="$store.state.loadingSe"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-large
                  rounded="sm"
                  no-wrap
                /> -->
                <div class="con-tab-ejemplo">
                  <div class="d-flex creator-list overflow-auto">
                      <div v-for="(data, index) in $store.state.dataCreator" :key="index">
                        <div class="card border-10 mt-3 mb-2 mr-3" style="width: 16rem;" type="button" @click="profileCreator(data._id)">
                          <div class="card-body">
                            <div class="d-flex align-items-center">
                              <img
                                  v-if="data.picture == null"
                                  :src="
                                    'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' +
                                    data.name
                                  "
                                  :alt="data.name"
                                  class="creator-profile rounded-circle"
                                />
                                <img
                                  :src="data.picture"
                                  :alt="data.name"
                                  style="border-radius: 50%"
                                  class="creator-profile rounded-circle"
                                  v-else
                                />
                              <div class="ml-2" style="width: 60%;">
                                <h3 class="text-truncate text-left">{{ data.name }}</h3>
                                <div class="d-flex mt-2">
                                  <img
                                      src="@/assets/images/icons/ic_play_grup_grey.svg"
                                      alt=""
                                      class="user-svg"
                                    />
                                    <p class="ml-1 text-white">{{ data.total_play }}</p>
                                    <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start-creator ml-3">
                                    <p class="ml-1 mb-0 text-white">{{ data.total_rating }}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div v-if="$store.state.loadingSe" class="d-flex quiz">
                    <div v-for="index in 5" :key="index">
                      <div
                        class="card mr-3 mt-4"
                        style="width: 16rem"
                      >
                      <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                          <div class="card-body text-left">
                          <div class="d-flex justify-content-between mb-2">
                            <b-skeleton animation="wave" width="30%"></b-skeleton>
                            <b-skeleton animation="wave" width="30%"></b-skeleton>
                            <b-skeleton animation="wave" width="30%"></b-skeleton>
                          </div>
                          <div class="d-flex mb-2">
                            <b-skeleton animation="wave" width="50%"></b-skeleton>
                            </div>
                          <b-skeleton animation="wave" width="80%"></b-skeleton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="$store.state.dataTop.length > 0" class="container-fluid">
                      <div>
                        <h3 class="text-left mt-3">Related Contents</h3>
                        <div class="row">
                          <div
                            v-for="(dataTrend, index) in $store.state.dataTop"
                            :key="index"
                            class="col"
                          >
                            <div
                              class="card mr-1 mt-3 top-card"
                            >
                              <img
                                v-if="dataTrend.images_cover == null"
                                src="@/assets/images/default-cover.png"
                                alt=""
                                class="card-img-top"
                                type="button"
                                @click="
                                  detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)
                                "
                              />
                              <img v-else :src="dataTrend.images_cover"  class="card-img-top" alt="" type="button" @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"/>
                              <div class="card-body text-left">
                                <div class="d-flex justify-content-between align-items-start">
                                  <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                  <!-- <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                      <template #button-content>
                                        <b-icon icon="three-dots-vertical"></b-icon>
                                      </template>
                                      <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                      <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="duplicate(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                      <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                    </b-dropdown> -->
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                  <div class="d-flex">
                                      <img
                                          src="@/assets/images/icons/ic_play_grup_grey.svg"
                                          alt=""
                                          class="user-svg img-fluid"
                                      />
                                      <p class="ml-1 mb-0 mr-3">{{dataTrend.total_play}}</p>
                                      <img
                                          src="@/assets/images/icons/ic_question_grup_grey.svg"
                                          alt=""
                                          class="user-svg img-fluid"
                                      />
                                      <p class="ml-1 mb-0">{{ dataTrend.total_question }}</p>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-3">
                                  <div class="creator-info d-flex align-items-center">
                                    <img
                                      v-if="dataTrend.author_quiz == 0"
                                      src="@/assets/images/logo/soca-logo.png"
                                      alt=""
                                      class="ml-2 rounded-circle"
                                    />
                                    <img v-else class="ml-2 rounded-circle" :src="dataTrend.data_creator[0].picture != null && dataTrend.data_creator[0].picture != '' ? dataTrend.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+dataTrend.creator_name " alt="user">

                                    <p v-if="dataTrend.author_quiz == 0" class="text-white ml-1">{{ dataTrend.author ? cutName(dataTrend.author) : '-'}},</p>
                                    <p v-else class="text-white ml-1">{{ dataTrend.creator_name ? cutName(dataTrend.creator_name) : '-'}},</p>
                                    <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                  </div>
                                  <div class="d-flex align-items-end">
                                    <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                    <p class="text-white ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                        <div class="sorry mt-5">
                          <div v-if="$store.state.dataTop.length == 0 && $store.state.dataCreator.length == 0">
                            <img src="@/assets/images/icons/data-none.png" alt="">
                            <h3 class="mt-2 ml-2">Sorry...</h3>
                            <h5 class="font-weight-normal mt-2">The content is not found.</h5>
                            <h5 class="font-weight-normal mt-1">Let's check on other contents!</h5>
                          </div>
                          <div class="trending-quiz">
                            <div class="container-fluid mt-5 p-0">
                              <div class="d-flex justify-content-between justify-content-center">
                                <h3 class="text-left">Yang lagi populer</h3>
                              </div>

                              <div v-if="loadingQuizPopuler" class="d-flex quiz">
                                <div
                                class="card mr-3 mt-4"
                                style="width: 16rem"
                              >
                                <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                  <div class="card-body text-left">
                                    <div class="d-flex justify-content-between mb-2">
                                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                                      <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    </div>
                                    <div class="d-flex mb-2">
                                      <b-skeleton animation="wave" width="50%"></b-skeleton>
                                      </div>
                                    <b-skeleton animation="wave" width="80%"></b-skeleton>
                                  </div>
                                </div>
                              </div>
                              <div v-else class="quiz d-flex mt-3">
                                <div v-for="(dataTrend, index) in quizPopuler.slice(0, 5)" :key="index">
                                  <div
                                    class="card mr-3"
                                    style="width: 16rem"
                                  >
                                    <img
                                      :src="dataTrend.images_cover"
                                      alt=""
                                      v-if="
                                        dataTrend.images_cover != null &&
                                        dataTrend.images_cover != ''
                                      "
                                      class="card-img-top"
                                      type="button"
                                      @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"
                                    />
                                    <img src="@/assets/images/default-cover.png" class="card-img-top" alt="" type="button" v-else @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"/>
                                    <div class="card-body text-left">
                                      <div class="d-flex justify-content-between align-items-start">
                                        <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                        <!-- <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                            <template #button-content>
                                              <b-icon icon="three-dots-vertical"></b-icon>
                                            </template>
                                            <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                            <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="duplicate(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                            <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                          </b-dropdown> -->
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mb-2">
                                        <div class="d-flex">
                                            <img
                                                src="@/assets/images/icons/ic_play_grup_grey.svg"
                                                alt=""
                                                class="user-svg img-fluid"
                                            />
                                            <p class="ml-1 mb-0 mr-3">{{dataTrend.total_play}}</p>
                                            <img
                                                src="@/assets/images/icons/ic_question_grup_grey.svg"
                                                alt=""
                                                class="user-svg img-fluid"
                                            />
                                            <p class="ml-1 mb-0">{{ dataTrend.total_question }}</p>
                                        </div>
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mt-3">
                                        <div class="creator-info d-flex align-items-center">
                                          <img
                                            src="@/assets/images/logo/soca-logo.png"
                                            alt=""
                                            class="ml-2 rounded-circle"
                                          />
                                          <p class="text-white ml-1">{{ cutName(dataTrend.author)}},</p>
                                          <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                        </div>
                                        <div class="d-flex align-items-end">
                                          <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                          <p class="text-white ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </vs-tab>
              <vs-tab label="Creator">
                <b-overlay
                  :show="$store.state.loadingSe"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-large
                  rounded="sm"
                  no-wrap
                />
                <div class="con-tab-ejemplo">
                  <div class="container-fluid tab-creator">
                    <div v-if="$store.state.dataCreator.length > 0">
                      <div class="row">
                        <div v-for="(data, index) in $store.state.dataCreator" :key="index" class="col-md-3">
                            <div class="card border-10 mt-3 mb-2 mr-3 card-top" type="button" @click="profileCreator(data._id)">
                              <div class="card-body">
                                <div class="d-flex align-items-center">
                                  <img
                                    v-if="data.picture == null"
                                    :src="
                                      'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' +
                                      data.name
                                    "
                                    alt=""
                                    class="creator-profile rounded-circle"
                                  />
                                  <img
                                    :src="data.picture"
                                    alt=""
                                    style="border-radius: 50%"
                                    class="creator-profile rounded-circle"
                                    v-else
                                  />
                                  <div class="ml-2" style="width: 60%;">
                                    <h3 class="text-truncate text-left">{{ data.name }}</h3>
                                    <div class="d-flex mt-2">
                                      <img
                                          src="@/assets/images/icons/ic_play_grup_grey.svg"
                                          alt=""
                                          class="user-svg"
                                        />
                                        <p class="ml-1 text-white">{{data.total_play}}</p>
                                        <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start-creator ml-3">
                                        <p class="ml-1 mb-0 text-white">{{data.total_rating}}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="sorry mt-5">
                        <img src="@/assets/images/icons/data-none.png" alt="">
                        <h3 class="mt-2 ml-2">Sorry...</h3>
                        <h5 class="font-weight-normal mt-2">The content is not found.</h5>
                        <h5 class="font-weight-normal mt-1">Let's check on other contents!</h5>

                        <div class="trending-quiz">
                          <div class="container-fluid mt-5 p-0">
                            <div class="d-flex justify-content-between justify-content-center">
                              <h3 class="text-left">Yang lagi populer</h3>
                            </div>

                            <div v-if="loadingQuizPopuler" class="d-flex quiz">
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                                class="card mr-3 mt-4"
                                style="width: 16rem"
                              >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                            </div>
                            <div v-else class="quiz d-flex mt-3">
                              <div v-for="(dataTrend, index) in quizPopuler.slice(0, 5)" :key="index">
                                <div
                                  class="card mr-3"
                                  style="width: 16rem"
                                >
                                  <img
                                    :src="dataTrend.images_cover"
                                    alt=""
                                    v-if="
                                      dataTrend.images_cover != null &&
                                      dataTrend.images_cover != ''
                                    "
                                    class="card-img-top"
                                    type="button"
                                    @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"
                                  />
                                  <img src="@/assets/images/default-cover.png" class="card-img-top" alt="" type="button" v-else @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"/>
                                  <div class="card-body text-left">
                                    <div class="d-flex justify-content-between align-items-start">
                                      <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                      <!-- <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                          <template #button-content>
                                            <b-icon icon="three-dots-vertical"></b-icon>
                                          </template>
                                          <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                          <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="duplicate(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                          <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                        </b-dropdown> -->
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataTrend.total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataTrend.total_question }}</p>
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                      <div class="creator-info d-flex align-items-center">
                                        <img
                                          src="@/assets/images/logo/soca-logo.png"
                                          alt=""
                                          class="ml-2 rounded-circle"
                                        />
                                        <p class="text-white ml-1">{{ cutName(dataTrend.author)}},</p>
                                        <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                      </div>
                                      <div class="d-flex align-items-end">
                                        <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                        <p class="text-white ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vs-tab>
              <vs-tab label="Tag">
                <b-overlay
                  :show="$store.state.loadingSe"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-large
                  rounded="sm"
                  no-wrap
                />
                <div class="con-tab-ejemplo">
                  <div class="container-fluid tab-tag">
                    <div v-if="$store.state.dataTag.length > 0">
                      <div
                        v-for="(data, index) in $store.state.dataTag"
                        :key="index"
                        class="mt-3"
                      >
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <h3 class="text-left ml-1">
                              {{ data.category_name }}
                            </h3>
                          </div>
                          <!-- <div
                            v-if="data.data_quiz_all.length >= 6"
                            class="mt-2"
                          >
                            <a href="#" class="text-primary mr-1"
                              >Lihat semua <b-icon icon="chevron-right"></b-icon
                            ></a>
                          </div> -->
                        </div>
                        <div class="quiz d-flex mt-3">
                          <div
                            v-for="(
                              dataTrend, indexQuiz
                            ) in data.data_quiz_all.slice(0, 6)"
                            :key="indexQuiz"
                          >
                            <div
                              class="card mr-4"
                              style="width: 16rem"
                            >
                              <img
                                v-if="dataTrend.images_cover == null"
                                src="@/assets/images/default-cover.png"
                                alt=""
                                type="button "
                                @click="
                                  detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)
                                "
                                class="img-form card-img-top"
                              />
                              <img :src="dataTrend.images_cover" alt="" class="card-img-top" type="button " @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"/>
                              <div class="card-body text-left">
                                <div class="d-flex justify-content-between align-items-start">
                                  <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                  <!-- <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                      <template #button-content>
                                        <b-icon icon="three-dots-vertical"></b-icon>
                                      </template>
                                      <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                      <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="duplicate(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                      <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                    </b-dropdown> -->
                                </div>
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                  <div class="d-flex">
                                      <img
                                          src="@/assets/images/icons/ic_play_grup_grey.svg"
                                          alt=""
                                          class="user-svg img-fluid"
                                      />
                                      <p class="ml-1 mb-0 mr-3">{{dataTrend.total_play}}</p>
                                      <img
                                          src="@/assets/images/icons/ic_question_grup_grey.svg"
                                          alt=""
                                          class="user-svg img-fluid"
                                      />
                                      <p class="ml-1 mb-0">{{ dataTrend.total_question }}</p>
                                  </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-3">
                                  <div class="creator-info d-flex align-items-center">
                                    <img
                                    v-if="dataTrend.author_quiz == 0"
                                    src="@/assets/images/logo/soca-logo.png"
                                    alt=""
                                    class="ml-2 rounded-circle"
                                  />
                                  <img v-else class="ml-2 rounded-circle" :src="dataTrend.data_creator[0].picture != null && dataTrend.data_creator[0].picture != '' ? dataTrend.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+dataTrend.creator_name " alt="user">

                                    <p v-if="dataTrend.author_quiz == 0" class="text-white ml-1">{{ dataTrend.author ? cutName(dataTrend.author) : '-'}},</p>
                                    <p v-else class="text-white ml-1">{{ dataTrend.creator_name ? cutName(dataTrend.creator_name) : '-'}},</p>
                                    <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                  </div>
                                  <div class="d-flex align-items-end">
                                    <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                    <p class="text-white ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="sorry mt-5">
                        <img src="@/assets/images/icons/data-none.png" alt="">
                        <h3 class="mt-2 ml-2">Sorry...</h3>
                        <h5 class="font-weight-normal mt-2">The content is not found.</h5>
                        <h5 class="font-weight-normal mt-1">Let's check on other contents!</h5>

                        <div class="trending-quiz">
                          <div class="container-fluid mt-5 p-0">
                            <div class="d-flex justify-content-between justify-content-center">
                              <h3 class="text-left">Yang lagi populer</h3>
                            </div>

                            <div v-if="loadingQuizPopuler" class="d-flex quiz">
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                              class="card mr-3 mt-4"
                              style="width: 16rem"
                            >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                              <div
                                class="card mr-3 mt-4"
                                style="width: 16rem"
                              >
                              <b-skeleton-img card-img="top" class="skeleton-img"></b-skeleton-img>
                                <div class="card-body text-left">
                                  <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                  </div>
                                  <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                    </div>
                                  <b-skeleton animation="wave" width="80%"></b-skeleton>
                                </div>
                              </div>
                            </div>
                            <div v-else class="quiz d-flex mt-3">
                              <div v-for="(dataTrend, index) in quizPopuler.slice(0, 5)" :key="index">
                                <div
                                  class="card mr-3"
                                  style="width: 16rem"
                                >
                                  <img
                                    :src="dataTrend.images_cover"
                                    alt=""
                                    v-if="
                                      dataTrend.images_cover != null &&
                                      dataTrend.images_cover != ''
                                    "
                                    class="card-img-top"
                                    type="button"
                                    @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"
                                  />
                                  <img src="@/assets/images/default-cover.png" class="card-img-top" alt="" type="button" v-else @click="detailQuiz(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)"/>
                                  <div class="card-body text-left">
                                    <div class="d-flex justify-content-between align-items-start">
                                      <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                      <!-- <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-white p-0" no-caret>
                                          <template #button-content>
                                            <b-icon icon="three-dots-vertical"></b-icon>
                                          </template>
                                          <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz, dataTrend.quiz_name)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                          <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="duplicate(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                          <b-dropdown-item class="d-flex align-items-center" v-if="is_login == true" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                        </b-dropdown> -->
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                      <div class="d-flex">
                                          <img
                                              src="@/assets/images/icons/ic_play_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0 mr-3">{{dataTrend.total_play}}</p>
                                          <img
                                              src="@/assets/images/icons/ic_question_grup_grey.svg"
                                              alt=""
                                              class="user-svg img-fluid"
                                          />
                                          <p class="ml-1 mb-0">{{ dataTrend.total_question }}</p>
                                      </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mt-3">
                                      <div class="creator-info d-flex align-items-center">
                                        <img
                                          src="@/assets/images/logo/soca-logo.png"
                                          alt=""
                                          class="ml-2 rounded-circle"
                                        />
                                        <p class="text-white ml-1">{{ cutName(dataTrend.author)}},</p>
                                        <p class="ml-1 mb-0 text-truncate" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                      </div>
                                      <div class="d-flex align-items-end">
                                        <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                        <p class="text-white ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="report-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
      <div class="d-flex justify-content-between my-4 mx-4">
          <div class="text-left d-flex align-items-center">
              <img src="@/assets/images/icons/report-icon.svg" alt="" class="mr-3 mt-1">
              <h3 class="font-weight-bold">Laporkan</h3>
          </div>
          <div class="text-right">
              <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('report-quiz')"><b-icon icon="x"></b-icon></button>
          </div>
      </div>
      <div class="mx-4">
          <div>
              <h4>Mengapa kamu melaporkan quiz ini?</h4>
              <div v-for="(data, index) in optionReport" :key="index" class="mt-3">
                  <div class="form-check d-flex align-items-center my-2">
                      <input class="form-check-input" type="radio" name="exampleRadios" :id="'report' + index" v-model="formReport.report" :value="data.text" checked>
                      <label class="form-check-label ml-2" :for="'report' + index">
                          <h5 class="font-weight-normal">{{ data.text }}</h5>
                      </label>
                  </div>
              </div>
          </div>
          <div class="pt-3">
              <h4>Deskripsi</h4>
              <div class="form-group">
                  <label for="deskripsiReport"></label>
                  <textarea v-model="formReport.detail_report" class="form-control" id="deskripsiReport" rows="3"></textarea>
              </div>
          </div>
          <div class="float-right">
              <div class="d-flex">
                  <button class="btn btn-secondary btn-grey mr-1 text-white" @click="$bvModal.hide('report-quiz')">Cancel</button>
                  <button class="btn btn-warning text-white" @click="reportQuizViolations(idQuiz)">Submit</button>
              </div>
          </div>
          <!-- <b-form-group v-slot="{ ariaReport }">
              <b-form-radio-group
                  size="sm"
                  v-model="reportSelected"
                  :options="optionReport"
                  :aria-describedby="ariaReport"
                  name="plain-stacked"
                  plain
                  stacked
              ></b-form-radio-group>
          </b-form-group> -->
      </div>
    </b-modal>

    <QuizPlayed />
  </div>
</template>

<script>
import { getCategoriesAll } from "@/services/quiz-categories/quiz_categories.service";
import { getCategoriesDetail } from "@/services/quiz-categories/quiz_categories.service";
import { reportQuiz, getQuizPopuler } from "@/services/quiz/quiz.service";
import { getSearchEngine } from "@/services/general/general.service";
import {getQuestions} from '@/services/question/question.service'
import { getQuestionCreator } from '@/services/question-creator/question_creator.service'
import { base_url_home } from '@/config/base_url'
import { getCheckPlay } from "@/services/check-play/check_play.service";
import { mapActions } from "vuex";

// MODAL
import QuizPlayed from '@/components/modal/QuizPlayed'
import Swal from 'sweetalert2';

export default {
  components: {
    QuizPlayed
  },
  data() {
    return {
      filter: null,
      currentPageDetail: 1,
      perPage: 10,
      currentPageDetailPopuler: 1,
      perPagePopuler: 10,
      currentPageDetailBahasa: 1,
      perPageBahasa: 10,
      currentPageDetailBerhitung: 1,
      perPageBerhitung: 10,
      currentPageDetailBisnis: 1,
      perPageBisnis: 10,
      currentPageDetailChristmas: 1,
      perPageChristmas: 10,
      userAuth: "",
      filterKategori: null,
      titleSeeAll : "",
      loadingCategory : false,
      loadingQuizForYou  : false,
      loadingQuizPopuler : false,
      loadingQuizBahasa : false,
      loadingQuizBerhitung : false,
      loadingQuizBisnis : false,
      loadingQuizChristmas : false,
      loadingScroll : false,
      loadingScrollPopuler : false,
      loadingScrollBahasa : false,
      loadingScrollBerhitung : false,
      loadingScrollBisnis : false,
      loadingScrollChristmas : false,
      math: true,
      bahasa: true,
      biologi: true,
      loading: false,
      quiz_categories: [],
      seeAll : false,
      is_category: false,
      statusIpad : false,
      id_active: null,
      users:{
          _id: null,
          email: null,
      },
      formReport : {
          origin_quiz : null,
          report : null,
          detail_report : null,
      },
      detail_category: [
        {
          _id: null,
          category_name: null,
          cagory_icon: null,
          data_quiz_all: [],
        },
      ],
      dataTop: [],
      dataCreator: [],
      linkShare : '',
      getId: "",
      dataTag: [
        {
          category_id: null,
          category_name: null,
          _id: null,
          data_quiz_all: [],
        },
      ],
      quiz: [],
      quizMixBahasa : [],
      quizMixBerhitung : [],
      quizMixBisnis : [],
      quizMixChristmas : [],
      quizPopuler: [],
      count: 1,
      dataQuestion : [],
      idQuiz : null,
      report_author: null,
      is_login : false,
      useCaseTrendingQuiz: [
        {
          title: "Sistem Pemasaran",
          image: "Pemasaran & Penjualan.svg",
        },
        {
          title: "Simple Present",
          image: "Bahasa Inggris.svg",
        },
        {
          title: "Bahasa Indonesia",
          image: "Bahasa Indonesia.svg",
        },
        {
          title: "Ekonomi Dagang",
          image: "ekonomi.svg",
        },
        {
          title: "Desain Seni Budaya",
          image: "seni-budaya.svg",
        },
      ],
      useCaseMath: [
        {
          title: "Pecahan",
          image: "pecahan.svg",
        },
        {
          title: "Perkalian",
          image: "pertambahan.svg",
        },
        {
          title: "Gravitasi",
          image: "gravitasi.svg",
        },
        {
          title: "Unsur",
          image: "unsur.svg",
        },
        {
          title: "Ikatan Kimia",
          image: "ikatan-kimia.svg",
        },
      ],
      useCaseBudaya: [
        {
          title: "Bahasa Mandarin",
          image: "Bahasa Mandarin.svg",
        },
        {
          title: "Basic Bahasa Jepang",
          image: "Bahasa Jepang.svg",
        },
        {
          title: "Branding",
          image: "Digital Marketing.svg",
        },
        {
          title: "Istilah Dalam Tari",
          image: "tari.svg",
        },
        {
          title: "Chord Nada",
          image: "musik.svg",
        },
      ],

      useCaseSBK: [
        {
          title: "Sistem Reproduksi",
          image: "reproduksi.svg",
        },
        {
          title: "Metabolisme",
          image: "metabolisme.svg",
        },
        {
          title: "Teknologi & Jaringan",
          image: "tkj.svg",
        },
        {
          title: "Luas Wilayah",
          image: "geografi.svg",
        },
        {
          title: "Sosiologi",
          image: "sosiologi.svg",
        },
      ],

      creatorQuiz: [
        {
          user_name: "dadang kasbon",
          quiz: [
            {
              title: "Sistem Reproduksi",
              image: "reproduksi.svg",
            },
            {
              title: "Metabolisme",
              image: "metabolisme.svg",
            },
            {
              title: "Teknologi & Jaringan",
              image: "tkj.svg",
            },
            {
              title: "Luas Wilayah",
              image: "geografi.svg",
            },
            {
              title: "Sosiologi",
              image: "sosiologi.svg",
            },
          ],
        },
        {
          user_name: "dadang kasbon",
          quiz: [
            {
              title: "Sistem Reproduksi",
              image: "reproduksi.svg",
            },
            {
              title: "Metabolisme",
              image: "metabolisme.svg",
            },
            {
              title: "Teknologi & Jaringan",
              image: "tkj.svg",
            },
          ],
        },
      ],

      tagQuiz: [
        {
          tag_name: "Fisika",
          quiz: [
            {
              title: "Sistem Reproduksi",
              image: "reproduksi.svg",
            },
            {
              title: "Metabolisme",
              image: "metabolisme.svg",
            },
            {
              title: "Teknologi & Jaringan",
              image: "tkj.svg",
            },
            {
              title: "Luas Wilayah",
              image: "geografi.svg",
            },
            {
              title: "Sosiologi",
              image: "sosiologi.svg",
            },
          ],
        },
        {
          tag_name: "IPA",
          quiz: [
            {
              title: "Sistem Reproduksi",
              image: "reproduksi.svg",
            },
            {
              title: "Metabolisme",
              image: "metabolisme.svg",
            },
            {
              title: "Teknologi & Jaringan",
              image: "tkj.svg",
            },
          ],
        },
      ],

      creatorFav : [
        {
          name : 'Jhonny',
          desc : 'Design Grapihc',
          content : '200',
          plays : '1,2k',
          players : '20k',
          image : '1.png',
          icon : 'pencil-circle-badge.svg'
        },
        {
          name : 'Silvia',
          desc : 'Informatika',
          content : '200',
          plays : '1,2k',
          players : '20k',
          image : '2.png',
          icon : 'atom-circle-badge.svg'
        },
        {
          name : 'Dadun',
          desc : 'Chemical Analyst',
          content : '200',
          plays : '1,2k',
          players : '20k',
          image : '3.png',
          icon : 'tas-circle-badge.svg'
        },
        {
          name : 'Silvia',
          desc : 'Informatika',
          content : '200',
          plays : '1,2k',
          players : '20k',
          image : '2.png',
          icon : 'atom-circle-badge.svg'
        },
      ],
      optionReport : [
          {
              text : 'Pelanggaran hak kekayaan intelektual',
              value : 1,
          },
          {
              text : 'Ujaran atau simbol kebencian',
              value : 2,
          },
          {
              text : 'Isi konten berisi tidak pantas',
              value : 3,
          },
          {
              text : 'Lainnya',
              value : 4,
          },
      ],
      dummyCreator : [
        {
          name : 'Isman Karbon'
        },
        {
          name : 'Naufal Ahnaf'
        },
        {
          name : 'Karbon Keplar'
        },
        {
          name : 'Kamu'
        },
        {
          name : 'Isman Karbon'
        },
      ]
    };
  },
  mounted() {
    this.getQuizPopuler();
    this.getCategories();
    this.getParamsRouteDynamic();
    this.cekLogin();
    if(this.$store.state.searchStat == false || this.$store.state.filterSearchEngine == null || this.$store.state.filterSearchEngine == ''){
      this.$router.push({ name : 'My-Quiz' })
    }
  },

  computed: {
    searchQuery() {
      if (this.filter) {
        return this.quiz.filter((item) => {
          return this.filter
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.quiz_name.toLowerCase().includes(v)
            );
        });
      } else {
        return this.quiz;
      }
    },
  },
  methods: {
    ...mapActions(["setAuthor"]),

    cekLogin(){
      let users = localStorage.getItem("users")
      let id_client = localStorage.getItem("id_client")

      if(users){
          this.is_login = true;
      } else if(id_client) {
          this.is_login = true;
      }
    },

    getQuizPopuler(){
      this.loadingScrollPopuler = true;
      var data = {
        page: this.currentPageDetailPopuler,
        limit: this.perPagePopuler,
      }
      getQuizPopuler(data)
      .then((response) => {
        if (response.status && response.status == true) {
          this.quizPopuler = this.quizPopuler.concat(response.data)
          this.currentPageDetailPopuler = this.currentPageDetailPopuler + 1
          if(response.data.length == 0){
            this.loadingScrollPopuler = false;
          }
          this.loadingQuizPopuler = false;
        } else {
          this.loadingScrollPopuler = false;
          this.loadingQuizPopuler = false;
        }
      })
    },

    seeAllQuiz(item){
      this.$router.push({ name: "See-All", query: { type : item } })
    },

    reportQuizViolations(id){
            if(this.report_author == '0'){
                this.formReport.origin_quiz = "template"
            } else {
                this.formReport.origin_quiz = "creator"
            }
            reportQuiz(id, this.formReport)
            .then((response) => {
                if(response.status && response.status == true){
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Report sukses !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                    this.report_author = null;
                } else {
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Report gagal !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                    this.report_author = null;
                }
            })
    },

    getCategories() {
      getCategoriesAll()
        .then((response) => {
          if (response.status) {
            this.$vs.loading.close();
            let allKategori = response.data
            this.quiz_categories = allKategori.reverse();
          } else {
            this.$vs.loading.close();
            this.quiz_categories = [];
          }
        })
        .catch((err) => {
          this.$vs.loading.close();
          console.log("data err", err);
        });
    },
    detailQuiz(id, author, name) {
      localStorage.removeItem("quiz_name_temp")
      localStorage.removeItem("description_temp")
      localStorage.removeItem("images_cover_temp")
      localStorage.removeItem("category_id_temp")
      localStorage.removeItem("grade_temp")
      localStorage.removeItem("sub_grade_temp")
      localStorage.removeItem("index_grade_temp")
      console.log(name)

      this.setAuthor(author)

      this.$router.push({
        name: "Detail-Library",
        query: { id: id, author: author },
        // params: { id : id, name : name.replace(/ /g, '-') },
      });
    },
    profileCreator(id){
      this.$router.push({
        name: "Profile-Creator",
        query: { key: id },
      });
    },
    getParamsRouteDynamic() {
      var split_url = window.location.href;
      var res = split_url.split("/");
      this.userAuth = res[3];

      if(this.userAuth == 'auth_key'){
        localStorage.setItem("id_client", res[4])
        // window.location = "http://localhost:8080/";
        window.location = base_url_home + 'templates';
      }
    },

    searchEngine(e) {
      if (e.target.value != null && e.target.value != "") {
        this.$store.state.searchStat = true;
        this.dataTop = [];
        this.dataCreator = [];
        this.dataTag = [
          {
            category_id: null,
            category_name: null,
            _id: null,
            data_quiz_all: [],
          },
        ]

        let usersLogin = localStorage.getItem("users");
        let usersNotLogin = sessionStorage.getItem("users_id")

        let data = {}
        if(usersLogin){
          let parseUser = JSON.parse(usersLogin);

          data = { search: this.filter, user_id : parseUser._id};
        } else if(usersNotLogin) {
          data = { search: this.filter, user_not_login_id : usersNotLogin};
        } else {
          data = { search: this.filter};
        }
        this.loading = true;
        getSearchEngine(data).then((response) => {
          if (response.status && response.status == true) {
            //Data TOP
            if (response.data.top.length > 0) {
              let topTemplate = response.data.top[0];
              topTemplate.forEach((e) => {
                this.dataTop.push(e);
              });

              // cek if data top length more then 1
              if (response.data.top.length > 1) {
                let topNoTemplate = response.data.top[1];
                topNoTemplate.forEach((e) => {
                  this.dataTop.push(e);
                });
              }
            }

            //Data Creator
            this.dataCreator = response.data.creator;

            //Data Tag
            let tags = response.data.tags;
            let index = 0;
            if(index+1 <= tags.length){
              tags.forEach((e) => {
                this.dataTag[index].category_id = e.category_id;
                this.dataTag[index].category_name = e.category_name;
                this.dataTag[index].data_quiz_all = e.data_quiz_template.concat(e.data_quiz_creator)

                this.dataTag.push(e)
                index++;
              });
            }
            this.dataTag.splice(index, 1)

            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      } else {
        this.$store.state.searchStat = false;
      }
    },

    setSearch(e) {
      if (e.target.value == null || e.target.value == "") {
        this.$store.state.searchStat = false;
      }
    },

    modalReport(id, author){
      this.idQuiz = id
      this.report_author = author;
      this.$bvModal.show('report-quiz')
    },

    getKategori(id) {
      (this.detail_category = [
        {
          _id: null,
          category_name: null,
          cagory_icon: null,
          data_quiz_all: [],
        },
      ]),
      this.loadingCategory = true;
      this.id_active = id;
      let usersLogin = localStorage.getItem("users");
      let usersNotLogin = sessionStorage.getItem("users_id")

      let data = {}
      if(usersLogin){
        let parseUser = JSON.parse(usersLogin);

        data = { user_id : parseUser._id};
      } else if(usersNotLogin) {
        data = { user_not_login_id : usersNotLogin};
      } else {
        data = { };
      }
        getCategoriesDetail(id, data).then((response) => {
          if (response.status && response.status == true) {
            this.is_category = true;
            let kategori = response.data;
            let index = 0;
            kategori.forEach((e) => {
              this.detail_category[index]._id = e._id;
              this.detail_category[index].category_name = e.category_name;
              this.detail_category[index].category_icon = e.category_icon;
              e.data_quiz_template.forEach((t) => {
                this.detail_category[index].data_quiz_all.push(t);
              });

              e.data_quiz_creator.forEach((t) => {
                this.detail_category[index].data_quiz_all.push(t);
              });
              index++;
            });
            if (this.getId == id) {
              this.id_active = null;
              this.is_category = false;
              this.seeAll = false
              this.getId = ""
              // window.location.reload();
            }
            this.getId = id;
            this.loadingCategory = false;
          } else {
            this.loadingCategory = false;
          }
        });
    },

    getDate(item){
      var publish = new Date(item);

      var seconds = Math.floor((new Date() - publish) / 1000);

      var interval = seconds / 31536000;

      if (interval > 1) {
        return Math.floor(interval) + " years ago";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months ago";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days ago";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours ago";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes ago";
      }
      return Math.floor(seconds) + " seconds ago";
    },

    cekCategoryName(item){
      const name = item.split(" ")
      if(name.length > 1){
        return name[0] + "<br /> " + name[1]
      } else {
        return name[0]
      }
    },
    cutName(item){
      if(item != undefined){
        const name = item.split(" ")

        return name[0]
      } else {
        return "-"
      }
    },

    share(id, author, name){
      this.linkShare ='https://soca.ai/quiz/detail-quiz/' + id + '/' +  author +  '/' + name.replace(/ /g, '-')

      this.$copyText(this.linkShare).then(function () {
          alert('Berhasil copy link')
        }, function () {
          alert('Gagal untuk copy link')
        })
      // return this.linkShare
    },

    onCopy: function (e) {
        alert('Berhasil Copy link : ' + e.text)
    },

    onError: function () {
        alert('Gagal untuk copy link !')
    },

    duplicate(id,author){
      localStorage.removeItem("quiz_name_temp")
      localStorage.removeItem("description_temp")
      localStorage.removeItem("images_cover_temp")
      localStorage.removeItem("category_id_temp")
      localStorage.removeItem("grade_temp")
      localStorage.removeItem("sub_grade_temp")
      localStorage.removeItem("index_grade_temp")

            if(author == '0'){
                getQuestions(id).then((response)=>{
                if(response.status){
                    this.$vs.loading.close();
                    this.dataQuestion = response.data;
                    this.$router.push({ name: 'Edit-Template', query: {key: id, author} })
                    localStorage.setItem('listQuestion', JSON.stringify(this.dataQuestion));
                }
                }).catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            } else {
                getQuestionCreator(id)
                .then((response)=>{
                    if(response.status){
                      this.$vs.loading.close();
                      this.dataQuestion = response.data;
                      this.$router.push({ name: 'Edit-Template', query: {key: id, author} })
                      localStorage.setItem('listQuestion', JSON.stringify(this.dataQuestion));
                    }
                })
                .catch((err)=>{
                    this.$vs.loading.close();
                    console.log("data err", err)
                })
            }
        },

        getMinQuiz(length){

            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        },

        getSubQuiz(str){
          const value = str.toUpperCase();
          const search = this.filter.toUpperCase();
          const result = value.indexOf(search);
          if (!search || result === -1) {
              return str; // bail early
          }
          const final = search.length;
          return '<b>' + str.substr(0, result) + '</b>' + str.substr(result, final) + '<b>' + str.substr(result + final) + '</b>';
        },

        playQuizChristmas(id, category_id){
            let users = {}

            if(localStorage.getItem('users')){
              users = JSON.parse(localStorage.getItem('users'))
              getCheckPlay(id)
              .then((response) => {
                if(response.status == true){
                  window.location = "https://play.soca.ai/instance/template/" + id + "/" + users.token + "/" + category_id
                } else {
                  this.$bvModal.show('quiz-played-modal')
                }
              })
            } else {
              this.$bvModal.show('signIn')
            }
        },
  },
};
</script>

<style scoped>

.course-inner{
  padding-left: 16rem;
}

.img-search-engine {
  width: 38px;
  height: 30px;
}

.inner-quiz{
  border-radius: 24px;
}

.inner-quiz h5 {
  font-size: 15px;
}

.inner-quiz-border{
  border: 3px solid #7280FF;
}

.badge-icon{
  width : 2rem !important;
  height : 2rem !important;
  right : 0;
  bottom : 0;
  z-index : 4;
}

.avatar-badge{
  z-index: 2;
  width: 110px;
  height: 110px;
  background-color : #fff;
  border-radius : 50%
}

.card .card-img-top {
    width: 100%;
    height: 10.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.creator-profile {
  width: 85px;
  height: 85px;
}


.search-bar {
  height: 3rem;
  width: 100%;
  border-radius: 25px;
  border: 0px;
  padding-left: 48px !important;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.search-bar-result {
  height: 7vh;
  width: 100%;
  border-radius: 20px 20px 0 0;
  border-bottom: 1px solid #e5e5e5 !important;
  border: 0px;
  padding-left: 48px !important;
  padding-right: 20px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

.result-search {
  margin-top: 10px;
  border-radius: 20px 20px 20px 20px;
}

.result-search .result {
  overflow-y: auto;
  height: 15rem;
}

.result-search .result::-webkit-scrollbar {
    width: 6px;
    margin-right: 10px ;
}

        /* Track */
.result-search .result::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

        /* Handle */
.result-search .result::-webkit-scrollbar-thumb {
    background: #B6B6B6;
    border-radius: 4px;
}

        /* Handle on hover */
.result-search .result::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.header .form-control {
  padding: 10px 30px;
}

.border-10 {
  border-radius : 10px !important;
}

.card-info{
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background-color: #F1F1F1;
}

.header label {
  position: relative;
  width: 100%;
  height: 40px;
  border-radius: 25px !important;
  z-index: 2;
}

.header label:before {
  content: "";
  position: absolute;
  left: 1rem;
  top: 10px;
  bottom: 0;
  width: 40px;
  background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
  background-size: 20px;
}

.search-engine .card {
  border-radius: 15px !important;
}

.search-engine p {
  margin: 0;
  font-size: 12px;
}

.search-engine .creator-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.category-type .card {
  border-radius: 15px !important;
}

.category-type p {
  color: #8c8c8c;
  margin: 0;
  font-size: 12px;
}

.category-type .creator-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.creator-img-search {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.creator-favorite img {
  /* width: 83px;
  height: 83px; */
  width: 100px;
  height: 100px;
  border-radius : 50%;
}

.creator-favorite h4 {
  font-size : 16px
}

.creator-favorite hr {
    border:none;
    border-left: 1px hsla(200, 10%, 50%,100);
    color: #D9D9D9;
    width:1px;
    height: 4rem;
}

.creator-favorite h5 {
  font-size : 14px;
}

.trending-quiz .card,
.math .card,
.budaya .card,
.sbk .card, .creator-favorite .card {
  border-radius: 15px !important;
}

.trending-quiz p,
.math p,
.math p,
.budaya p,
.sbk p, .creator-favorite p, .course-content p {
  margin: 0;
  font-size: 12px;
}

.trending-quiz .quiz,
.course-content .quiz,
.math .quiz,
.budaya .quiz,
.sbk .quiz,
.tab-tag .quiz, .tab-creator .quiz{
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: auto;
}

.trending-quiz .quiz::-webkit-scrollbar,
.math .quiz::-webkit-scrollbar,
.budaya .quiz::-webkit-scrollbar,
.sbk .quiz::-webkit-scrollbar,
.course-content .quiz::-webkit-scrollbar,
.tab-tag .quiz::-webkit-scrollbar, .tab-creator .quiz::-webkit-scrollbar, .creator-favorite .quiz::-webkit-scrollbar, .creator-list::-webkit-scrollbar {
  display: none;
}

.skeleton-img{
  border-radius: 15px !important;
}

.img-category{
  width: 2rem !important;
  height: 2rem !important;
}
.category-list{
  border: 3px solid #7280FF;
  border-radius: 50%;
  width: 84px !important;
  height: 84px !important;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 60%;
  background-color: transparent !important;
  border: 1px solid #2D2D2D;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.icon-start{
  width: 18px;
  height: 18px;
}

.icon-start-creator{
  width: 15px;
  height: 15px;
}
.categories-card{
  width: 16rem;
}

.top-card{
    width: 16rem;
}

.card-all {
    width: 16rem;
}

.btn-close{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.btn-grey{
    background-color: #EDEDED;
    border-color : #EDEDED;
}

#report-quiz h3{
    font-size: 24px;
}

#report-quiz h4{
    font-size: 20px;
}

#report-quiz h5{
    font-size: 16px;
}

#report-quiz img{
    height: 24px;
    width: 20px;
}


#report-quiz input[type="radio"]{
    width: 22px;
    height: 22px;
}

#report-quiz .card, #report-quiz textarea{
    border-radius : 10px !important;
}

.list-quiz .circle-quiz {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .list-quiz .circle-quiz::-webkit-scrollbar {
    display: none;
  }
@media (max-width: 1366px){
  .categories-card{
    width: 13.8rem;
  }

  .top-card{
    width: 13.5rem;
  }

  .card .card-img-top {
    width: 100%;
    height: 10.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }

  .card-all {
    width: 15rem;
  }

}

@media (max-width: 768px) {
  .list-quiz .circle-quiz {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .list-quiz .circle-quiz::-webkit-scrollbar {
    display: none;
  }

  .mrm-auto {
    margin-right: 5vh;
  }
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none !important;
}
</style>

<style>
.dropdown-menu{
  border-radius: 10px !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none !important;
}

.banner .inner-banner .carousel .carousel-inner .carousel-item img {
  border-radius:10px !important;
}

.banner .inner-banner .carousel-indicators{
    position: absolute;
    /* right: 0; */
    bottom: -7px;
    left: -44rem;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.banner .inner-banner .carousel-indicators li{
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
</style>