import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getMixContent(data) {
    return axios({
      method: 'GET',
      url: ApiRoute.getMixContent,
      params: data
    }).then((res) => {
      const data = {
        status: res.data.status,
        data: res.data.data,
        message: res.data.message
      }
      return data;
    }).catch((error) => {
      const data = { status: false, response: error }
      return data;
    })
  }